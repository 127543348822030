import React from 'react'


import Gallery from "../components/gallery";

class RootIndex extends React.Component {
    render() {


        return (
            <Gallery/>
            )
    }
}

export default RootIndex


